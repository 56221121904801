
.list-title {
  font-size: 1.5em;
  margin: 10px 0 5px 10px;
  text-align: left;
}
.ant-list-item-meta {
  padding-left: 15px;
}
.ant-list-item.hide {
  background: #d9d9d9;
}
.ant-list-item.active {
  background: #e6f7ff;
}
